/*
* @Author: WYY
* @Description: 发音练习
* @ModuleName: 发音练习
* @Date: 2018-09-25 18:16:49
 * @Last Modified by: WYY
 * @Last Modified time: 2020-03-03 17:12:16
*/
<style lang="less" scoped>
    .question_des {
        background: #fff;
        border: none;
        border-radius: 5px;

        .Pr_title {
            font-size: 20px;
            letter-spacing: 0px;
            color: #cccccc;
            text-align: center;
            margin-bottom: 30px;
        }

        .Pr_img {
            display: block;
            margin: 0 auto;
            height: 2.4rem;
        }
    }
</style>

<template>
        <div class="T_box">
            <Head :Target='Target' :RightLeft='RightLeft' @show='pshow=true' :UpLayerData='UpLayerData' />
            <!-- 题干音频 -->
            <!-- <video :id="`myElement`" class="video-js vjs-default-skin vjs-big-play-centered flex-grid">
            </video> -->
            <!-- 作答提示音 -->
            <!-- <video :id="`Hint`" class="video-js vjs-default-skin vjs-big-play-centered flex-grid">
            </video> -->
             <AudioPlayer ref="HintAudioPlayer"  :theUrl="HintAudioPlayerUrl" @audioEndedOver="HintPlayerComplete" :config="HintAudioPlayerConfig" />
            <div class="T_content">
                <div class="Unit_d">
                    <div class="Unit_con">
                        
                        <!-- <div class="ShuiYinRight">中国民用航空西北地区空中交通管理局 </div> -->
                        <!-- <div class="ShuiYinLeft">{{Username}}</div> -->
                        <!-- 播放 -->
                        <div class="Second_title">
                            <div class="Audio_play">
                                <span class="t1" style="font-weight:bold;font-style:italic;">NO.
                                    {{questionIndex + 1}}/{{questionAll}}</span>
                                    <AudioPlayer ref="stemAudioPlayer" v-show="Pattern!='测试模式'" :theUrl="AudioPlayerUrl" @pauseall="pauseAll"
                                    @audioEndedOver="PlayerComplete" @changeAudioState="changeListeningTextStatus" :config="AudioPlayerConfig" />
                                <div class="describe" @click="pshow = !pshow"  v-if="Pattern!='测试模式'">
                                    <img :src=" require( '@/assets/shuoming.png')" alt="">
                                    <span>Directions</span>
                                </div>
                            </div>
                        </div>

                        <div class="Question_box clearfix">
                            <div :class="['question_des',(threeActive=='')?'active':'']">
                                <div class="question_des flex" style="height:100%">
                                    <h1 class="Pr_title" >{{ status == 'PlayRecording' ? 'Play recording': (status == 'ListeningText' ? 'Listening text': 'Start recording')}} ... </h1>
                                    <img :src=" require('../../assets/' + status +'.png') " v-if="!InPlay" class="Pr_img" alt="">
                                </div>
                            </div>

                            <div class="Text" v-show="threeActive=='TEXT'">
                                <div class="title">Text <i class="el-icon-close right" @click="threeShowClose"></i>
                                </div>
                                <div style="height:100%;">
                                    <div class="t_content" v-html="questionData.Texta" style="border:none"></div>
                                </div>
                            </div>
                            <div class="Notes" v-show="threeActive=='NOTES'">
                                <div class="title"> Notes <i class="el-icon-close right" @click="threeShowClose"></i>
                                </div>
                                <div class="t_content">
                                    <textarea name="" v-model="NoteItem.content" @focus="NoteFocus()" @blur="NoteBlur()"
                                        maxlength="500" id="" cols="30" rows="10"></textarea>
                                    <img v-if="NoteItem.content.length<=0" src="../../assets/notesbc.png" class="bc"
                                        alt="">
                                    <button class="save" @click="SaveNotes"> Save </button>
                                </div>
                            </div>
                            <!-- key -->
                            <div class="Text" v-show="threeActive=='KEY'">
                                <div class="title">Key <i class="el-icon-close right" @click="threeShowClose"></i></div>
                                <div class="t_content" v-html="AnswerKey"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="T_option">
                    <div class="Unit_option">
                        <div class="btn_list">
                            <!-- back -->
                            <span class="btn" v-if="Pattern!='测试模式'" @click="On_back()"></span>
                            <!-- next -->
                            <span class="btn2" v-if="Pattern!='测试模式'" @click="On_next()"></span>

                            <div class="Record_btn" v-if="RecordeItem.show">
                                <img src="../../assets/Microphone.png" class="T_1" alt="" @click="Record_a('1')">

                                <img src="../../assets/play_bai.png" v-if="Pattern!='测试模式' && !RecordeItem.MediaInPlay && recorderPlayerUrl && !RecordBtn"
                                    class="T_2" alt="" @click='MediaPlay()'>
                                <img src="../../assets/pause.png" v-if="Pattern!='测试模式' && RecordeItem.MediaInPlay && recorderPlayerUrl && !RecordBtn"
                                    class="T_2" alt="" @click='MediaPlay()'>

                                <div class="Re_conm" v-if='RecordBtn'>
                                    <span class="horn"></span>
                                    <div class="Re_box">
                                        <div id="Wave">
                                            <div v-for="i in 10" class="WaveItem" :key="i"
                                                :style="'height:'+ Wheight(i) +'px'">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="countdown"> {{RecordeItem.timeshow}} </div>
                                </div>
                            </div>
                            <Recorder ref="Recorder" @upRcData="AddAudio"></Recorder>
                            <AudioPlayer ref="recorderPlayer" @audioEndedOver="recorderPlayerAudioEndedOver" :theUrl="recorderPlayerUrl"  :config="recorderPlayerConfig" />
                            <!-- <span v-if="Pattern!='测试模式'&&ScoreShow&&ComplateTest=='1'&&isJudgment=='1'"
                                style="font-size:18px;display:inline-block;vertical-align:middle;"> 等级：{{this.TiScore}}
                            </span>
                            <span v-if="!ScoreShow&&Pattern!='测试模式'&&ComplateTest=='1'&&isJudgment=='1' "
                                style="font-size:18px;display:inline-block;vertical-align:middle;">打分中</span> -->
                            <div class="btn_cover" v-if='RecordBtn'></div>
                        </div>
                        <Time :Pattern='Pattern' />
                    </div>
                </div>
            </div>


            <!-- <div class="Machine"  v-if="Pattern!='测试模式'">
                <img v-if="mshow.text==0" src="../../assets/TEXT3.png" alt="" style="cursor:not-allowed;">
                <img v-if="mshow.text==1" src="../../assets/1TEXT.png" alt="" @click="Clicktext">
                <img v-if="mshow.text==2" src="../../assets/2TEXT.png" alt=""  @click="mshow.text=1">
                <img v-if="mshow.notes==0" src="../../assets/NOTES3.png" alt=""  style="cursor:not-allowed;">
                <img v-if="mshow.notes==1" src="../../assets/1NOTES.png" alt=""  @click="Clicknotes">
                <img v-if="mshow.notes==2" src="../../assets/2NOTES.png" alt=""  @click="mshow.notes=1">
                <img v-if="mshow.key==0" src="../../assets/KEY3.png" alt=""  style="cursor:not-allowed;">
                <img v-if="mshow.key==1" src="../../assets/1KEY.png" alt="" @click="CheckKey()">
                <img v-if="mshow.key==2" src="../../assets/2KEY.png" alt="" @click="mshow.key=1">
            </div> -->

            <popup :show.sync="pshow" :describe='this.Target.description'></popup>
            <div class="Re_cover" v-if='RecordBtn'></div>
        </div>

</template>

<script>
    import popup from '../describe/Popup.vue'
    import Head from '../../components/head.vue'
    import videoJs from 'video.js'
    import "video.js/dist/video-js.min.css"
    
    export default {
        data() {
            return {
                mshow: {
                    text: 1,
                    notes: 1,
                    key: 1,
                    // 0 不可选 1 可选 2 已选
                },
                Panduan: true,
                Target: {
                    catalog: '',
                    description: '',
                    isEnd: null,
                    title: '',
                },
                RightLeft: false,
                pshow: false,
                questionData: {},
                childQuestionData: {},
                questionIndex: 0,
                questionAll: 0,
                childQuestionListIndex: 0, // 当前子题
                childQuestionListAll: 0, // 所有子题 长度
                AnswerKey: '',

                // 播放器 参数  命名 不要重复   mixin 中使用了 参数 
                GetTime: false, // 获取 视频总时长
                audioTime: 0, // 滑块 value
                player: null, // 播放器
                playertime: '', //总时长
                InPlay: false, // 判断是否 播放中
                // 播放器 参数

                check: '',
                complate: false,
                answer: '',

                NoteItem: {
                    id: '',
                    content: '',
                },
                // 绑题 规则 
                BindRole: {},


                // 树形 上层数据
                UpLayerData: {},

                InfoComplate: false, // 首次加载数据判断


                // 录音 
                RecordeItem: {
                    show: true,
                    time: null,
                    timeshow: '',
                    Count: null,
                    begin: false,
                    getAudio: null,
                    RecorderAudio: 0, //  判断  当前 音频为录音  或  已录音音频    0 未录音   1 已录音在线音频   2本地录制音频
                    Media: null, // 录音播放 多媒体
                    MediaInPlay: false, // 录音播放状态
                },

                // 波浪纹  动画
                wave: null,
                waveIndex: 0,

                // 提示音
                Hint: null,

                ProgressBar: {
                    show: false,
                    width: 100,
                    time: null,
                    duration: 0,
                    timeing: 0,
                },
                AudioPlayerUrl:"",//题干音频地址
                AudioPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[1,2,3,4],
                },
                HintAudioPlayerUrl:"",//提示音频地址
                HintAudioPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[],
                },
                recorderPlayerUrl:"",//录音音频地址
                recorderPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[],
                },
                status:"ListeningText" , // 页面状态 听录音:PlayRecording  听题:ListeningText  录音:StartRecording
                reg1:/\\r\\n/g,
                reg2:/\\n/g,

            }
        },
        components: {
            popup,
            Head
        },
        created() {
            if (this.menulist.length > 0) {
                this.Panduan = true;
                this.getinfo();
            } else {
                this.Panduan = false;
            }

        },
        mounted() {
            let Question_box = document.querySelector('.Question_box').clientHeight;
            let list = document.querySelectorAll('.Question_box>div');
            for (let i = 0; i < list.length; i++) {
                const element = list[i];
                element.style.height = (Question_box-17) + 'px';
            }
        },
        computed: {
            datacomplate: function () {
                return this.$store.state.common.datacomplate;
            },
            checkid: function () {
                return this.$store.state.common.checkid;
            },
            RecordBtn: function () {
                return this.$store.state.common.RecordBtn;
            },
            testPause: function () {
                return this.$store.state.common.testPause;
            },
        },
        watch: {
            datacomplate: function (v) {
                if (!this.Panduan) {
                    this.getinfo();
                }
            },
            checkid: function () {
                if (this.InfoComplate) {
                    this.getinfo();
                }
            },
            testPause: function (v, oldv) {
                if (this.Pattern == '测试模式') {
                    if (v && !oldv && !this.complate) { // 开始
                        if (!!this.QuestionChange) {
                            this.QuestionChange();
                        } else {
                            if (!!this.pauseBegin) this.pauseBegin();
                        }
                    }
                    if (oldv && !v) { // 暂停
                        if (!!this.stayPause) this.stayPause();
                    }
                }
            },
            threeActive: function (v) {
                if (v == 'KEY' && !!this.CheckKey) {
                    this.CheckKey();
                }
            },
        },
        methods: {
            stayPause() { // 测试模式暂停
                if (this.complate) {
                    this.$message.warning('您本题已提交答案,无法暂停!');
                    this.$store.commit('setitem', {
                        name: 'testPause',
                        item: true
                    });
                    return false;
                }
                this.InPlay = false;
                if (!!this.player) this.player.pause();
                if (!!this.Hint) this.Hint.pause();
                if (!!this.twicePlayTimeOut) clearTimeout(this.twicePlayTimeOut)
                // 录音播放停止
                if (this.RecordeItem.MediaInPlay) {
                    if (this.RecordeItem.RecorderAudio == 1) {
                        this.RecordeItem.Media.pause();
                    }
                    this.RecordeItem.MediaInPlay = false;
                }
                if (this.RecordBtn) {
                    clearInterval(this.wave);
                    clearInterval(this.RecordeItem.Count);
                    Recorder.stop();
                    this.$store.commit('setitem', {
                        'name': 'RecordBtn',
                        'item': false
                    })
                }
            },
            Wheight(i) {
                let t = i - 1;
                let c = Math.abs(Math.abs((Math.floor(t / 5) * 5 - t % 5)) * 40 - 300) - 30;
                let d = 300 * 300 - c * c;
                d = Math.sqrt(d) * 2;
                return d;
            },

            async getinfo() {
                this.RecordeItem.Media = document.createElement('audio');
                let data = this.GetTargetItem();
                this.UpLayerData = data.updata; // 上 层数据
                if (!!data.last) {
                    this.Target = data.data[data.last];
                } else {
                    this.Target = data.data;
                }
                this.Target.title = data.title;
                // 判断 学习模式
                this.$store.commit('setitem', {
                    'name': 'Pattern',
                    'item': this.Target.bindRole.ruleTypeName
                });
                this.$store.commit('setitem', {
                    'name': 'RoleIsJudgment',
                    'item': this.Target.bindRole.isJudgment
                });

                // this.$store.commit('setitem',{'name':'Pattern','item':'测试模式'});

                // 判断 绑题规则 设置 notes key txt
                this.BindRole = this.Target.bindRole;
                this.threeShow(this.BindRole);
                if (this.BindRole.isRecordNote == 1) this.mshow.notes = 1;
                else this.mshow.notes = 0;

                if (this.BindRole.isReadAnswer == 1) this.mshow.key = 1;
                else this.mshow.key = 0;

                if (this.BindRole.isReadOriginal == 1) this.mshow.text = 1;
                else this.mshow.text = 0;
                // 判断 绑题规则 设置 notes key txt
                if (this.Target.isEnd == 1 && data.data.length > 1) {
                    this.RightLeft = true;
                } else {
                    this.RightLeft = false;
                }

                let qIndex = sessionStorage.getItem('questionIndex') || 0; // 问题下标
                this.questionIndex = sessionStorage.getItem('SonquestionIndex') || 0; // 问题子题下标
                this.questionAll = this.Target.questionList.length; // 问题 总数
                let questiondata = await this.GetQuestion(this.Target.questionList[qIndex].oldId, this.Target
                    .sectionMain.id);
                this.questionData = questiondata.data; // 当前问题 数据

                // this.childQuestionData = this.questionData.childQuestionList[0];
                this.childQuestionData = this.questionData;
                // this.childQuestionData.title = JSON.parse(this.childQuestionData.title);
                if (!!this.questionData.questionText && this.questionData.questionText != 'null' && this
                    .questionData.questionText != '') {
                    this.questionData.questionText = this.questionData.questionText.replace(this.reg1, '<br>');
                    this.questionData.questionText = this.questionData.questionText.replace(this.reg2, '<br>');
                    this.questionData.Texta =
                        `<div>${this.questionData.questionText}</div>`;
                } else {
                    this.questionData.Texta = '';
                }
                
                // let notes_ = await this.AjaxApi.getNoteByQuestionId(this.questionData.childQuestionList[0].id);
                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.questionData.id);
                if (!!notes_.data && notes_.data.length > 0) {
                    this.NoteItem = notes_.data[0];
                } else {
                    this.NoteItem.content = '';
                    this.NoteItem.id = '';
                }

                // 录音查询
                this.$refs.recorderPlayer.pausePlay()
                this.RecordeItem.MediaInPlay = false; 
                let record_ = await this.AjaxApi.getNewVideo(this.childQuestionData.id);
                if (!!record_.data && !!record_.data.filePath) {
                    this.recorderPlayerUrl = record_.data.filePath
                    this.RecordeItem.RecorderAudio = '1';
                }else{
                    this.recorderPlayerUrl = null
                }
                if(this.questionData.childQuestionList == null ){
                    this.childQuestionListAll = 1; // 当前问题 子题总数
                }else{
                    this.childQuestionListAll = this.questionData.childQuestionList.length; // 当前问题 子题总数
                }

                
                // 赋值音频路径
                this.AudioPlayerUrl = this.questionData.textResource
                if(this.BindRole.isAutoPaly == 1){
                    this.AudioPlayerConfig.autoplay = true
                    this.status = 'ListeningText'
                }else{
                    this.AudioPlayerConfig.autoplay = false
                }
                // let src = this.FlashUrl(this.questionData.textResource);
                // this.FlashPlay(src);
                this.InfoComplate = true;
            },
            async CheckKey() {
                let url_ = await this.AjaxApi.getAnswerById(this.questionData.childQuestionList[this
                    .childQuestionListIndex].id);
                let answerKey = '';
                if (!!url_.data.answers && url_.data.answers != null) answerKey =
                    `<span>Answer</span><div>${url_.data.answers}</div> `;
                if (!!url_.data.answerKey && url_.data.answerKey != null) answerKey +=
                    `<span>Keyword</span><div>${url_.data.answerKey}</div>`;
                this.AnswerKey = answerKey;
                this.mshow.key = 2;
                if (this.mshow.notes != 0) this.mshow.notes = 1;
                if (this.mshow.text != 0) this.mshow.text = 1;
            },
            // 保存 笔记
            async SaveNotes() {
                let questionId = ""
                if(this.questionData.childQuestionList){
                    questionId = this.questionData.childQuestionList[this.childQuestionListIndex];
                }else{
                    questionId = this.questionData.id;

                }
                let item = this.NoteItem;
                item.content = item.content.replace(/(^\s*)|(\s*$)/g, '');
                if (!!!item.content) {
                    this.$message.warning('请填写笔记!');
                    return false;
                }

                let layer = sessionStorage.getItem('targetceng').split(',');
                layer = layer.map(el => {
                    el = el.split('.');
                    return el;
                })
                let data = {
                    courseId: this.menulist[layer[0][1]].courceId,
                    nodeId: this.Target.id,
                    title: '',
                    content: item.content,
                    questionId: this.questionData.id,
                    levelName: this.Target.catalog,
                }

                if (!!item.id) {
                    let edit_ = await this.AjaxApi.updateCourseNote(item);
                    if (edit_.code == '200') {
                        this.$message.success('修改笔记成功!');
                    }
                } else {
                    let add_ = await this.AjaxApi.addCourseAdvice(data);
                    if (add_.code == '200') {
                        this.$message.success('添加笔记成功!');
                        this.NoteItem.id = add_.data.id;
                    }
                }

            },

            // 录音  控制
            Record_a(it) {
                if (it == '1') {
                    if (this.Pattern == '测试模式') {
                        return;
                    }
                }
                this.$store.commit('setAdd', 'MenuSwitch');
                // 先暂停所有音频播放
                let audios = document.getElementsByTagName('audio');
                [...audios].forEach((item) => {
                    item.pause()
                })
                this.RecordeItem.MediaInPlay = false
                // 检测是否有麦克风
                let checkInfo = JSON.parse( sessionStorage.checkInfo ) 
                if( !!!checkInfo.audioInput){
                    this.$message.warning("无音频输入设备!");
                    return
                }

                this.waveIndex = 0;
                this.recordTime = (parseInt(this.BindRole.recordDownTime) || 35) - this.RecordeItem.time;
                if (!!this.BindRole.recordDownTime && this.BindRole.recordDownTime > 0) {
                    this.RecordeItem.time = this.BindRole.recordDownTime;
                } else {
                    this.RecordeItem.time = 35;
                }
                this.RecordeItem.timeshow = window.ComputedTime((this.RecordeItem.time || 35));

                if (this.RecordBtn) {
                    clearInterval(this.wave);
                    clearInterval(this.RecordeItem.Count);
                    let list = document.querySelectorAll('#Wave div');
                    for (let index = 0; index < list.length; index++) {
                        let el = list[index];
                        el.className = '';
                    }
                    this.RecordeItem.RecorderAudio = 2;
                    Recorder.stop();
                    this.UpdateAudio();
                    this.$store.commit('setitem', {
                        'name': 'RecordBtn',
                        'item': false
                    })
                } else {
                    this.$store.commit('setitem',{'name':'RecordBtn','item':true})
                    this.wave = setInterval(()=>{
                        let list = document.querySelectorAll('#Wave div');
                        if(list[this.waveIndex ].className.indexOf('active')>-1){
                            list[this.waveIndex ].className = '';
                        }else{
                            list[this.waveIndex ].className = 'active';
                        }
                        if(this.waveIndex<9){
                            this.waveIndex++;
                        }else{
                            this.waveIndex = 0;
                        }
                    },35)
                    // this.wave = setInterval(() => {
                    //     let list = document.querySelectorAll('#Wave div');
                    //     if (list[this.waveIndex].className.indexOf('active') > -1) {
                    //         list[this.waveIndex].className = '';
                    //     } else {
                    //         list[this.waveIndex].className = 'active';
                    //     }
                    //     if (this.waveIndex < 9) {
                    //         this.waveIndex++;
                    //     } else {
                    //         this.waveIndex = 0;
                    //     }
                    // }, 35)
                    this.RecordeItem.Count = setInterval(() => {
                        if (this.RecordeItem.time > 0) {
                            this.RecordeItem.time--;
                            this.RecordeItem.timeshow = window.ComputedTime(this.RecordeItem.time);
                        } else {
                            this.recordTime = (parseInt(this.BindRole.recordDownTime) || 35) - this.RecordeItem
                                .time;
                            this.RecordeItem.RecorderAudio = 2;
                            Recorder.stop();
                            this.UpdateAudio();
                            this.$store.commit('setitem', {
                                'name': 'RecordBtn',
                                'item': false
                            })
                            clearInterval(this.wave);
                            clearInterval(this.RecordeItem.Count);
                        }
                    }, 1000)
                    this.$refs.Recorder.handleBtnClick()
                    this.status = 'StartRecording'

                    this.$store.commit('setitem', {
                        'name': 'RecordBtn',
                        'item': true
                    })
                }

            },
            async UpdateAudio() {
                // let url = "/uploadFile";
                // let formFile = new FormData();
                // formFile.append("action", "UploadVMKImagePath");  
                // formFile.append('filename', 'mp3Name');
                // formFile.append("file" ,this.RecordeItem.audio); //加入文件对象
                // let url_;
                // try{
                //     url_ = await this.$upload.post(url,formFile);
                // }catch(e){
                //     this.$message.error('上传文件失败!');
                // }
                this.ComplateTest = '1';
                await this.$refs.Recorder.stopVoice()
                // let msg = await this.$refs.Recorder.getResData()
                // Recorder.upload({
                //     url: this.$store.state.common.UploadAudio,
                //     audioParam: "file",
                //     success: (msg) => {
                //         this.complate = true;
                //         this.AddAudio(msg);
                //     }
                // });
            },
            async AddAudio(url_) {
                this.recorderPlayerUrl = url_.data;
                let path = url_.data;
                // 新增录音记录
                let data = {
                    courseId: this.$store.state.common.curriculum,
                    filePath: path,
                    questionId: this.childQuestionData.id,
                    sectionId: this.Target.id,
                    recordTime: this.recordTime,
                    seatMealCourseId: sessionStorage.getItem('seatMealCourseId') || 'abc',
                    uuid: sessionStorage.getItem('uuid'),
                }
                let add_ = await this.AjaxApi.addVideo(data);
                this.status = 'PlayRecording'
                let fo = {
                    url: path,
                    type: '2',
                    bigTitleId: this.questionData.id,
                    smallTitleId: this.childQuestionData.id,
                    standardAnswer: this.questionData.questionText,
                }
                await this.GetScore(fo);
                if (this.Pattern == '测试模式') {
                    this.T_next(this.childQuestionListIndex, this.childQuestionListAll, this.questionIndex, this
                        .questionAll);
                }
            },

            //  滑块 tooltip
            formatTooltip(val) {
                if (!!this.player) {
                    let c = parseFloat((val / 100) * this.player.duration()).toFixed(2);
                    if (c != NaN && c != 'NaN') c += 's'
                    else c = 's'
                    return c;
                } else {
                    return '';
                }
            },
            // 录音播放
            MediaPlay() {
                if (this.RecordeItem.RecorderAudio == 0) { // 未录音
                    this.$message.warning('请先录制音频!');
                    return false;
                }
                this.$refs.recorderPlayer.startPlayOrPause()
                // if (this.InPlay) {
                //     this.InPlay = false;
                //     this.player.pause();
                // }
                // if (this.RecordeItem.MediaInPlay) {
                //     if (this.RecordeItem.RecorderAudio == 1) {
                //         this.RecordeItem.Media.pause();
                //     }
                //     if (this.RecordeItem.RecorderAudio == 2) {
                //         Recorder.stop();
                //     }
                // } else {
                //     if (this.RecordeItem.RecorderAudio == 1) {
                //         this.RecordeItem.Media.play();
                //     }
                //     if (this.RecordeItem.RecorderAudio == 2) {
                //         Recorder.play({ // 播放 监听
                //             progress: function (milliseconds) {},
                //             finished: this.PlayChange,
                //         });
                //     }
                // }
                this.RecordeItem.MediaInPlay = !this.RecordeItem.MediaInPlay;

            },
            // 提示音频播放 完成
            HintPlayerComplete() {
                if(this.BindRole.haveRecordDown == 1){
                    this.Record_a();
                }
            },
            // 录音播放完成
            recorderPlayerAudioEndedOver(){
                this.RecordeItem.MediaInPlay = !this.RecordeItem.MediaInPlay
            },
            // // 重新 播放
            // ResetTime() {
            //     console.log(this.RecordeItem,'重新播放');
            //     if (this.RecordeItem.RecorderAudio == 1) {
            //         this.RecordeItem.Media.pause();
            //     }
            //     if (this.RecordeItem.RecorderAudio == 2) {
            //         Recorder.stop();
            //     }
            //     this.RecordeItem.MediaInPlay = false;
            //     this.audioTime = 0;
            //     this.player.volume(1);
            //     this.player.currentTime(0.5);
            //     this.player.play();
            //     this.InPlay = true;
            // },
            // 滑块  播放时段改变
            TimeChange() {
                if (this.Pattern == '测试模式') {
                    return;
                }
                if (this.RecordeItem.RecorderAudio == 1) {
                    this.RecordeItem.Media.pause();
                }
                if (this.RecordeItem.RecorderAudio == 2) {
                    Recorder.stop();
                }
                this.RecordeItem.MediaInPlay = false;
                let time = (this.audioTime / 100) * this.player.duration();
                this.player.volume(1);
                this.player.currentTime(time);
                this.player.play();
                this.InPlay = true;
            },
            // 音频 播放 暂停
            PlayPause(it) {
                if (this.Pattern == '测试模式') {
                    return;
                }
                if (this.ClickLimit) {
                    return false;
                }
                this.ClickLimit = true;
                let d = window.PlayerState(this.player.paused());
                if (d) {
                    if (this.RecordeItem.RecorderAudio == 1) {
                        this.RecordeItem.Media.pause();
                    }
                    if (this.RecordeItem.RecorderAudio == 2) {
                        Recorder.stop();
                    }
                    this.RecordeItem.MediaInPlay = false;
                    this.player.play();
                    this.InPlay = true;
                } else {
                    this.player.pause();
                    this.InPlay = false;
                }
                setTimeout(() => {
                    this.ClickLimit = false;
                }, 1000)

            },
            // 音频播放完成 
            PlayerComplete() {
                let current = parseInt(sessionStorage.getItem('PlayTwice'));
                let {
                    isPlayTwice,
                    topicPlaybackInterval
                } = this.BindRole;
                if (isPlayTwice == 1 && current < 1) {
                    current++;
                    sessionStorage.setItem('PlayTwice', current);
                    let time = topicPlaybackInterval || 3;
                    time = parseInt(time) * 1000;
                    this.InPlay = true;
                    setTimeout(this.ResetTime, time);
                } else {
                    // 判断 是否有 提示音
                    if (this.BindRole.isNeedHintAudio == 1) { // 需要 提示音
                        this.HintAudioPlayerUrl = this.BindRole.hintAudio
                        this.HintAudioPlayerConfig.autoplay = true
                        // console.log(this.$refs.HintAudioPlayer);
                        // this.$refs.HintAudioPlayer && this.$refs.HintAudioPlayer.startPlayOrPause()
                        // let item = {
                        //     width:'500',
                        //     height:'500',
                        //     controls:true,
                        //     loop:false,
                        //     preload:'auto',
                        //     autoplay:true,
                        //     sources:[
                        //         {
                        //             type:"rtmp/mp4",
                        //             src:this.FlashUrl(this.BindRole.hintAudio),
                        //         }
                        //     ],
                        // }
                        // if(!!this.Hint){
                        //     this.Hint.reset(); //重置 video
                        //     this.Hint.src([
                        //         {
                        //             type: 'rtmp/mp4',
                        //             src:this.FlashUrl(this.BindRole.hintAudio),
                        //         },
                        //     ]);
                        //     this.Hint.load();
                        //     this.Hint.currentTime(0);
                        //     this.Hint.play();
                        //     return false;
                        // }
                        // console.log(this.FlashUrl(this.BindRole.hintAudio),'提示音');
                        // this.Hint = videoJs('Hint',item);
                        // this.player.on('ended',()=>{
                        //     console.log('提示音播放完成')
                        //     this.Record_a();
                        // })
                    } else {
                        if(this.BindRole.haveRecordDown == 1){
                            this.Record_a();
                        }
                    }
                }

            },
            // 上一题
            prevQuestion() {
                this.questionIndex--;
                this.QuestionChange();
            },
            // 下一题
            nextQuestion() {
                this.questionIndex++;
                this.QuestionChange();
            },
            // 数据 改变
            async QuestionChange() {
                // 数据还原
                if (!!this.ProgressBar && !!this.ProgressBar.time) clearInterval(this.ProgressBar.time);
                if (!!this.wave) clearInterval(this.wave);
                if (!!this.RecordeItem.Count) clearInterval(this.RecordeItem.Count);
                if (!!this.player) this.player.pause();
                if (!!this.Hint) this.Hint.pause();
                this.childQuestionListIndex = 0;
                this.GetTime = false; // 获取 视频总时长
                this.audioTime = 0; // 滑块 value
                this.playertime = ''; //总时长
                this.InPlay = false; // 判断是否 播放中
                this.check = ''; // 判断是否选择 答案
                this.complate = false; // 是否提交答案
                this.answer = ''; // 答案
                this.ProgressBar = {
                    show: false,
                    width: 100,
                    time: null,
                    duration: 0,
                    timeing: 0,
                }
                this.RecordeItem = {
                    show: true,
                    time: null,
                    timeshow: '',
                    Count: null,
                    begin: false,
                    getAudio: null,
                    RecorderAudio: 0, //  判断  当前 音频为录音  或  已录音音频    0 未录音   1 已录音在线音频   2本地录制音频
                    Media: null, // 录音播放 多媒体
                    MediaInPlay: false, // 录音播放状态
                }
                this.RecordeItem.Media = document.createElement('audio');

                let questiondata = await this.GetQuestion(this.Target.questionList[this.questionIndex].oldId, this
                    .Target.sectionMain.id);
                this.questionData = questiondata.data; // 当前问题 数据
                if(this.questionData.childQuestionList == null ){
                    this.childQuestionListAll = 1; // 当前问题 子题总数
                    this.childQuestionData = this.questionData
                }else{
                    this.childQuestionListAll = this.questionData.childQuestionList.length; // 当前问题 子题总数
                    this.childQuestionData = this.questionData.childQuestionList[0];
                    this.childQuestionData.title = (typeof (this.childQuestionData.title) == 'object') ? JSON.parse(this
                        .childQuestionData.title) : this.childQuestionData.title;
                }


                if (!!this.questionData.questionText && this.questionData.questionText != 'null' && this
                    .questionData.questionText != '') {
                    this.questionData.questionText = this.questionData.questionText.replace(this.reg1, '<br>');
                    this.questionData.questionText = this.questionData.questionText.replace(this.reg2, '<br>');
                    this.questionData.Texta =
                        `<div>${this.questionData.questionText}</div>`;
                } else {
                    this.questionData.Texta = '';
                }
                
                this.$refs.recorderPlayer.pausePlay()
                this.RecordeItem.MediaInPlay = false; 
                let record_ = await this.AjaxApi.getNewVideo(this.childQuestionData.id);
                if (!!record_.data && !!record_.data.filePath) {
                    this.recorderPlayerUrl = record_.data.filePath
                    this.RecordeItem.RecorderAudio = 1;
                }else{
                    this.recorderPlayerUrl = null
                }

                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.childQuestionData.id);

                if (!!notes_.data && notes_.data.length > 0) {
                    this.NoteItem = notes_.data[0];
                } else {
                    this.NoteItem.content = '';
                    this.NoteItem.id = '';
                }
                // 赋值音频路径
                this.AudioPlayerUrl = this.questionData.textResource
                if(this.BindRole.isAutoPaly == 1){
                    this.AudioPlayerConfig.autoplay = true
                    this.status = 'ListeningText'
                }else{
                    this.AudioPlayerConfig.autoplay = false
                }
                this.InfoComplate = true;
            },
            On_back() {
                this.ComplateTest = 0;
                this.T_back(this.childQuestionListIndex, this.hildQuestionListAll, this.questionIndex, this.questionAll)
            },
            On_next() {
                this.ComplateTest = 0;
                if (this.$store.state.common.MaxPattern == '闯关模式') {
                    if (this.RecordeItem.RecorderAudio != 0) {
                        this.T_next(this.childQuestionListIndex, this.childQuestionListAll, this.questionIndex, this
                            .questionAll);
                    } else {
                        this.$message.warning('请先完成本题!');
                    }
                } else {
                    this.T_next(this.childQuestionListIndex, this.childQuestionListAll, this.questionIndex, this
                        .questionAll);
                }
            },
            pauseAll(){
                this.RecordeItem.MediaInPlay=false;
            },

        },
        
        destroyed() {
            if(!!this.player){
                this.player.dispose();
            }
            if(!!this.Hint){
                this.Hint.dispose();
            }
            clearInterval(this.wave);
            clearInterval(this.RecordeItem.Count);
        },
    }
</script>

<style lang="less" scoped>
</style>